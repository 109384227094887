.header-container {
    text-align: left;
    color: #1f2a20;
  }
  
  .menu {
    display: flex;
    gap: 2.5vw;
    font-size: 20px;
  }
  
  .menu-item {
    cursor: pointer;
    position: relative;
  }
  
  .underline {
    height: 2px;
    width: 100%;
    background: #1f2a20;
    position: absolute;
    bottom: -5px;
    left: 0;
    opacity: 0;
    transform: scaleX(0);
    transition: all 0.3s ease;
  }
  
  .menu-item:hover .underline,
  .menu-item.active .underline {
    opacity: 1;
    transform: scaleX(1);
  }
  

@media (max-width: 900px) {
    .header-container {
        text-align: left; /* Center-align header text on mobile */
    }

    .menu {
        gap: 25px;
        font-size: 18px;
        flex-direction: column;
        align-items: left;
        width: 90%;
    }

    .menu-item {
        text-align: left;
    }
}