.content-text {
    margin-top: 30px;
    font-size: 19px;
    line-height: 1.6;
    width: 55%;
    text-align: justify;
    transition: width 0.15s ease; 
}

.education-text {
  line-height: 1;
  text-align: left;
  margin-top: 36px;
}

.education-list {
    list-style-type: disc; /* Display bullets for the list */
    margin-top: -10px; /* Space between intro and list */
    margin-left: 0px; /* Indent the list slightly */
    line-height: 2; /* Set specific line height for the list */
    font-size: 19px; /* Optional: Adjust the font size */
    text-align: left;
}

.contact-line {
  line-height: 2; /* Adjust the value as needed */
}

/* Content.css */

/* Target links within the content-text component */
.content-text a {
  color: #1f2a20 !important; 
}

/* Optionally, change color when hovering over the link */
.content-text a:hover {
  color: rgb(94, 94, 94) !important; /* Hover effect (optional) */
}

/* Responsive styling */
@media (max-height: 500px) {
    .content-text {
        width: 70%;
    }
}

@media (max-width: 900px) {
    .content-text {
        width: 90%; /* Ensure it fits smaller screens */
        font-size: 16px; /* Adjust font size for mobile */
        margin-top: 30px; /* Maintain spacing */
        text-align: justify; /* Keep text justified */
    }

    .education-text {
        text-align: left;
        line-height: 1.6;
    }

    .education-list {
        margin-left: -15px; /* Adjust margins for mobile */
        line-height: 2; /* Slightly tighter line height for smaller screens */
        font-size: 15px;
    }
}