:root {
  --language-switcher-bottom: 24px;
  --language-switcher-transition: 0.3s ease;
  --language-text-color: #1f2a20;
}

.language-switcher {
  position: fixed;
  bottom: var(--language-switcher-bottom);
  right: 4.5%;
  display: flex;
  font-size: 18px;
  z-index: 1000;
  transition: opacity var(--language-switcher-transition);
}

.language-option {
  cursor: pointer;
  position: relative;
  color: var(--language-text-color);
  padding: 8px 0;
  width: 100px;
  text-align: center;
  transition: all var(--language-switcher-transition);
}

.language-option:hover {
  font-weight: 600;
}

.language-option.selected {
  font-weight: 600;
}

.language-option .underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--language-text-color);
  transition: width var(--language-switcher-transition);
}

.language-option:hover .underline,
.language-option.selected .underline {
  width: 100%;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

@media (max-width: 900px) {
  .language-switcher {
    --language-switcher-bottom: 16px;
    right: 24px;
  }

  .language-option {
    width: auto;
    padding: 4px;
  }
}

@media (max-width: 480px) {
  .language-switcher {
    font-size: 16px;
  }
}

